import Location from '../assets/Location.jpg';
import Hotel from '../assets/Hotel.jpg';
import Facilities from '../assets/Facilities.jpg';

export const aboutMock = [
	{
		id: 1,
		title: 'Локация',
		description:
			'Наш отель находится в национальном парке Приэльбрусье (п. Нейтрино), ' +
			'и это гарантирует вам шикарные пейзажи и приятную атмосферу. До полян Азау и Чегет на машине всего 20 минут. ' +
			'Наше местоположение обеспечит вам удобный проезд до основных горнолыжных подъемников и подарит вам настоящий "релакс" прямо в центре березовой рощи.',
		image: Location,
	},
	{
		id: 2,
		title: 'Отель',
		description:
			'В нашем доме 10 просторных комфортабельных номеров с новым ремонтом и свежесобранной мебелью. ' +
			'Для эстетов у нас есть номера с шикарным видом на березовую рощу национального парка и могучие горы Кавказа. ' +
			'Мы считаем себя семейным бизнесом, и каждый наш гость будет чувствовать себя частью большой дружной семьи.',
		image: Hotel,
	},
	{
		id: 3,
		title: 'Удобства',
		description: `Завтрак в нашем отеле включён в стоимость всех категорий номеров. Обед и ужин вы можете заказать в нашем собственном кафе (очень советуем хычыны и шашлыки).
		
                  На третьем этаже функционирует зона отдыха, в которой вы всегда можете поиграть в бильярд, под успокаивающий треск дров в камине. 
                  
             			На расстоянии 200 метров находится настоящий нарзанный источник, тропа здоровья для любителей красивых видов и прогулок по лесу , а также продовольственная база со всем необходимым для жизни и отдыха.`,
		image: Facilities,
	},
];
